// import CryptoJS from 'crypto-js';

// const Encode = () => {
//     async function encryptPayload(url, payload) {
//       const plaintext = JSON.stringify(payload);
//       const secretKeyHex = process.env.REACT_APP_VITE_SECRET_KEY;
//       const ivHex = process.env.REACT_APP_VITE_IV;

//        // Check for Web Crypto API support
//     if (!window.crypto || !window.crypto.subtle) {
//       throw new Error("Web Crypto API is not supported in this environment.");
//     }
      
//       if (!secretKeyHex || !ivHex) {
//         throw new Error("Environment variables are not set correctly.");
//       }
  
//       const secretKeyBytes = new Uint8Array(secretKeyHex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
//       const ivBytes = new Uint8Array(ivHex.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
  
//       const key = await crypto.subtle.importKey('raw', secretKeyBytes, { name: 'AES-CBC' }, false, ['encrypt']);
  
//       const encoder = new TextEncoder();
//       const data = encoder.encode(plaintext);
  
//       const encrypted = await crypto.subtle.encrypt({ name: 'AES-CBC', iv: ivBytes }, key, data);
  
//       const encryptedBase64 = btoa(String.fromCharCode(...new Uint8Array(encrypted)));
//       console.log('Encrypted Data (Base64):', encryptedBase64);
  
//       try {
//         const response = await fetch(url, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ data: encryptedBase64 }),
//         });
  
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
  
//         const responseData = await response.json();
//         console.log('Server Response:', responseData);
  
//         return responseData;
//       } catch (error) {
//         console.error("Error:", error);
//         throw error;
//       }
//     }
  
//     return { encryptPayload };
//   };
  
//   export default Encode;
  
import CryptoJS from 'crypto-js';

const Encode = () => {
  function encryptPayload(url, payload) {
    const plaintext = JSON.stringify(payload);
    const secretKeyHex = process.env.REACT_APP_VITE_SECRET_KEY;
    const ivHex = process.env.REACT_APP_VITE_IV;
    
    if (!secretKeyHex || !ivHex) {
      throw new Error("Environment variables are not set correctly.");
    }

    const key = CryptoJS.enc.Hex.parse(secretKeyHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);

    const encrypted = CryptoJS.AES.encrypt(plaintext, key, { iv: iv });
    const encryptedBase64 = encrypted.toString();

    console.log('Encrypted Data (Base64):', encryptedBase64);

    return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: encryptedBase64 }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(responseData => {
      console.log('Server Response:', responseData);
      return responseData;
    })
    .catch(error => {
      console.error("Error:", error);
      throw error;
    });
  }

  return { encryptPayload };
};

export default Encode;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Post from "../Api/Post";
import { login } from "../Data/data";
import Encode from "../EcodeRequest/Encode";
const Auth = ({ children }) => {
  const navigate = useNavigate();
  const { encryptPayload } = Encode();

  const callonBackend = async  (number) => {
    let request = { msisdn: number };

    try {
      const response = await encryptPayload(login,request);
      console.log('Received Response:', response);
      handleResposne(response);
    } catch (error) {
      console.error('Error while encrypting payload:', error);
    }
  };

  const handleResposne = (e) => {
    if (e === 1) {
      navigate("/login");
      //billing pending
    } else if (e === 2) {
      // user can access the portal...
      return;
    } else if (e === 3) {
      // alert("Billing is pending");
      navigate("/login");
    } else {
      //billing pending
      navigate("/login");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("ani")) {
      callonBackend(localStorage.getItem("ani"));
    } else {
      navigate("/login");
    }
  }, []);
  return <>{children}</>;
};

export default Auth;
